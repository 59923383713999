import ApiConfig from "../../config/api";
import { WebServices } from "../WebServices";

const get = async (params) => {
    let url = ApiConfig.SERVICE_GET;
    if(params)
    {
        url += '?' + new URLSearchParams(params);
        //console.log(url);
    }
    else{
        url += '/all';
    }
    return WebServices.get(url);
}

const getAll = async (params) => {
    let url = ApiConfig.SERVICE_GET + '/all';
    if(params)
    {
        url += '?' + new URLSearchParams(params);
        // console.log(url);
    }
    return WebServices.get(url);
}

const getDetail = async (id) => {
    let url = ApiConfig.SERVICE_GET + "/" + id;
    return WebServices.get(url);
}


const add = async (data) => {
    let url = ApiConfig.SERVICE_ADD;
    return WebServices.post(url,data);
}

const update = async (data, id) => {
    let url = ApiConfig.SERVICE_EDIT + '/' + id;
    return WebServices.put(url,data);
}

const _delete = async (id) => {
    let url = ApiConfig.SERVICE_DELETE + '/' + id;
    return WebServices.delete(url);
}

const uploadTemplate = async (params, id, template_name='') => {
    let url = ApiConfig.SERVICE_TEMPLATE + '/' + id + '?template_name='+template_name;
    let data = new FormData();
    data.append("file",params.file);
    return WebServices.post(url,data);
}

const deleteTemplate = async (id) => {
    let url = ApiConfig.SERVICE_TEMPLATE + '/' + id;
    return WebServices.delete(url);
}

const addForm = async (data) => {
    let url = ApiConfig.SERVICE_FORM_ADD;
    return WebServices.post(url,data);
}

const updateForm = async (data, id) => {
    let url = ApiConfig.SERVICE_FORM_EDIT ;
    return WebServices.put(url,data);
}

const deleteForm = async (data) => {
    let url = ApiConfig.SERVICE_FORM_DELETE ;
    return WebServices.post(url,data);
}

export const Service = {
    get,
    getAll,
    getDetail,
    add,
    update,
    delete : _delete,
    uploadTemplate,
    deleteTemplate,
    addForm,
    updateForm,
    deleteForm
};
