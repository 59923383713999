import ApiConfig from "../../config/api";
import { WebServices } from "../WebServices";

const get = async (params=null) => {
    let url = ApiConfig.TRAINING_GET;
    if(params)
    {
        url += '?' + new URLSearchParams(params);
        //console.log(url);
    }
    return WebServices.get(url);
}


const getDetail = async (id) => {
    let url = ApiConfig.TRAINING_GET + '/' + id; 
    return WebServices.get(url);
}

const add = async (data) => {
    let url = ApiConfig.TRAINING_ADD;
    return WebServices.post(url,data);
}

const update = async (data, id) => {
    let url = ApiConfig.TRAINING_EDIT + '/' + id;
    return WebServices.put(url,data);
}

const _delete = async (id) => {
    let url = ApiConfig.TRAINING_DELETE + '/' + id;
    return WebServices.delete(url);
}

const upload_image = async (params) => {
    let url = ApiConfig.TRAINING_UPLOAD_IMAGE + '?id=' + params.id ;
    if(params.type)
    {
        url += '&type='+params.type;
    }
    let data = new FormData();
    data.append("file",params.file);
    return WebServices.post(url,data);
}

export const Training = {
    get,
    getDetail,
    add,
    update,
    delete : _delete,
    upload_image
};
