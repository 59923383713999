import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import PageContainer from '../../../components/container/PageContainer';
import NilaiSkm from './NilaiSkm';
import JumlahResponden from './JumlahResponden';
import ChartPerBulan from './ChartPerBulan';
import DataChartLayanan from './rekap_perlayanan/DataChartLayanan';
import Breadcrumb from '../../../layouts/full-layout/breadcrumb/Breadcrumb';
import TabelSurveyKepuasan from './rekap_kepuasan';

import {Report} from '../../../models/skm/report.model';


const DashboardSkm = () => {

  const [title, setTitle] = useState('Recap Survey');
  const [summary, setSummary] = useState([]);
  const [services, setServices] = useState([]);
  const [year, setYear] = useState('');

  useEffect(() => {
    getSummary();
    getServices();
  }, []);
  
  const getSummary = async() => {

    let param = {
      year : year
    }
    var result = await Report.summary(param);

    // console.log(result);

    if (result.hasOwnProperty("status") && result.status == false && result.message == "Invalid token") {
      localStorage.removeItem("user");
      window.location = "/auth/login";
    }

    setSummary(result);
  }

  const getServices = async() => {

    let param = {
      year : year
    }
    var result = await Report.services(param);

    // console.log(result);
    setServices(result);
  }


  const BCrumb = [
    {
      to: '/',
      title: 'Home',
    },
    {
      title: 'Survey',
    },
    {
      title: title,
    },
  ];

  return(
  <PageContainer title="Dashboard SKM" description="Dashboard">

<Breadcrumb title={title} items={BCrumb} />
    <Grid container spacing={0}>
      {summary && 
      <>
      <Grid item xs={12} lg={4}>
        <NilaiSkm data={summary.skm} />
        <JumlahResponden data={summary.responden} />
      </Grid>
      <Grid item xs={12} lg={8}>
        <ChartPerBulan data={summary.responden} />
      </Grid>
      </>}

      {services &&
      <Grid item xs={12} lg={12}>
        {services.map((service)=> (
          <DataChartLayanan data={service}/>
        ))}
      </Grid>}
  
      <Grid item xs={12} lg={12}>
        <TabelSurveyKepuasan/>
      </Grid>



    


    </Grid>
  </PageContainer>
)
}
;

export default DashboardSkm;
