import { combineReducers } from 'redux';
import CustomizerReducer from './customizer/CustomizerReducer';
import AuthReducer from './auth/AuthReducer';

const RootReducers = combineReducers({
  CustomizerReducer,
  AuthReducer
});

export default RootReducers;
