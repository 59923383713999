import React from "react";
import {
  Card,
  CardContent,
  Box,
  Button,
  Pagination,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  Avatar,
  Grid,
  Fab,
  IconButton,
} from "@mui/material";

import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import Breadcrumb from "../../../layouts/full-layout/breadcrumb/Breadcrumb";
import { useEffect, useState } from "react";

import Sweetalert from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import QuisionerFilter from "./QuisionerFilter";
import QuisionerData from "./QuisionerData";

import { Training } from "../../../models/training/training.model";

import { useSelector } from "react-redux";

import GlobalVar from "../../../config/globalvar.json";
import PageContainer from "../../../components/container/PageContainer";
import { Survey } from "../../../models/skm/survey.model";
import { Kuesioner } from "../../../models/skm/kuesioner.model";

const Quisioner = (props) => {
  //console.log(props);
  const [type, setType] = useState("0");
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [training_type, setTrainingType] = useState([]);
  const [loading, setLoading] = useState(false);

  const [paginator, setPaginator] = useState({});

  const [perpage, setPerpage] = useState(10);
  const [page, setPage] = useState(1);

  const Swal = withReactContent(Sweetalert);

  const [id, setId] = useState("");

  const [title, setTitle] = useState("Ref Quisioner");

  const user = useSelector((state) => state.AuthReducer.auth_data);

  const BCrumb = [
    {
      to: "/",
      title: "Home",
    },
    {
      title: "Survey",
    },
    {
      title: title,
    },
  ];

  const handlerSearch = (val) => {
    setPage(1);
    setSearch(val);
    loadData(val, 1, type);
  };

  const handletype = (val) => {
    setPage(1);
    setType(val);
    loadData(search, 1, val);
  };

  useEffect(() => {
    refresh();
    // console.log(user);
  }, [props]);

  const refresh = () => {
    loadData();
  };

  const loadData = async (s = search, p = page, status = type) => {
    if (!loading) {
      setLoading(true);
      var params = {
        page: p,
        per_page: perpage,
      };

      if (status != "0") {
        params.status = status;
      }

      if (s != "") {
        params.search = s;
      }

      if (user.company) {
        params.company = user.company;
      }

      if (user.lpk) {
        params.lpk = user.lpk;
      }

      var result = await Kuesioner.get(params, 
        {
          headers: {
            Authorization: "Bearer " + user.token,
        }
        });

      // console.log(result);

      if (
        result.hasOwnProperty("status") &&
        result.status == false &&
        result.message == "Invalid token"
      ) {
        localStorage.removeItem("user");
        window.location = "/auth/login";
      }

      if (Object.keys(result).length > 0) {
        var paginator = result.paginator;
        setPaginator(paginator);
        setData(result.itemsList);
        // console.log(result.itemsList);
      }
      setLoading(false);
    }
  };

  const handlerPageChange = (e, val) => {
    setPage(val);
    loadData(search, val);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Hapus data!",
      text: "Apakah anda yakin akan menghapus data ini?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
      toast: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        var response = await Kuesioner.delete(id);
        // console.log(response);
        if (response.status == true) {
          const Toast = Swal.mixin({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: false,
          });

          Toast.fire({
            icon: "success",
            title: "Data Berhasil Dihapus",
          });

          loadData();
        }
      }
    });
  };

  return (
    <PageContainer title={title} description={title}>
      <Box
        sx={{
          display: {
            xs: "block",
            sm: "flex",
            lg: "flex",
          },
          alignItems: "center",
          mt: 0,
        }}
      >
        <Breadcrumb title={title} items={BCrumb} />
        <Box paddingRight={2}>
          <Button
            variant="contained"
            style={{ borderRadius: "20px" }}
            color="primary"
            component={Link}
            to="/quisioner/add"
            startIcon={<FeatherIcon icon="plus" />}
          >
            Tambah
          </Button>
        </Box>
      </Box>

      <Card>
        <CardContent>
          <QuisionerFilter
            keyword={search}
            training_type={training_type}
            type={type}
            onSearch={handlerSearch}
            onChangetype={handletype}
          ></QuisionerFilter>

          <QuisionerData data={data} role={user.role} onDelete={handleDelete} loading={loading}/>
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              mb: 2,
              mt: 2,
            }}
          >
            {paginator.pageCount > 1 && !loading && (
            <Pagination
              onChange={handlerPageChange}
              defaultPage={1}
              page={page}
              count={paginator.pageCount}
              variant="outlined"
              color="primary"
            />
          )}
          </Box>
        </CardContent>
      </Card>
    </PageContainer>
  );
};

export default Quisioner;
