import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Box, Grid } from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import Chart from 'react-apexcharts';
import { useTheme } from '@mui/material/styles';

const JumlahResponden = (props) => {
  
  const [label, setLabel] = useState([]);
  const [value, setValue] = useState([]);

  useEffect(() => {
    let labels = [];
    let values = [];
    if(props.data)
    {
      let summary = props.data.summary;
      let i=0;
      for(i=0;i<summary.length;i++)
      {
        labels.push(summary[i].rating_desc);
        values.push(summary[i].jumlah);
      }
    }
  
    setLabel(labels);
    setValue(values);
  }, [props])
  

  

  const theme = useTheme();
  const primary = theme.palette.primary.main;
  // console.log(props.data);
  // chart
  const optionsyearlysales = {
    labels: label,

    chart: {
      height: 145,
      type: 'donut',
      foreColor: '#adb0bb',
      fontFamily: 'DM sans',
    },
    colors: ['#ff0d00', '#6a00ff','#00fffb' ,'#fec90f'],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    stroke: {
      colors: ['transparent'],
    },
    plotOptions: {
      pie: {
        donut: {
          size: '65%',
          background: 'transparent',
          labels: {
            show: false,
            name: {
              show: true,
              fontSize: '18px',
              color: undefined,
              offsetY: -10,
            },
            value: {
              show: false,
              color: '#98aab4',
            },
            total: {
              show: false,
              label: 'Our Visitors',
              color: '#98aab4',
            },
          },
        },
      },
    },
    tooltip: {
      theme: 'dark',
      fillSeriesColor: false,
    },
  };
  const seriesyearlysales = value;
  return (
    <Card>
      <CardContent>
        <Grid container spacing={0}>
          <Grid item xs={6} xl={7} display="flex" alignItems="center" justifyContent="flex-start">
            <Grid>
                <Typography
                  fontWeight="500"
                  variant="h1"
                  sx={{
                    lineHeight: '35px',
                  }}
                >
                  {(props.data) ? props.data.total : 0}
                </Typography>
                <Typography color="textSecondary" variant="h6">
                  Jumlah Responden
                </Typography>
            </Grid>
        
          </Grid>
          <Grid item xs={6} xl={5} display="flex" alignItems="center" justifyContent="flex-end">
            <Box
              sx={{
                position: 'relative',
                mt: 1,
              }}
            >
              <Chart
                options={optionsyearlysales}
                series={seriesyearlysales}
                type="donut"
                height="145"
              />
              <Typography
                color="textSecondary"
                sx={{
                  position: 'absolute',
                  left: '44%',
                  top: '38%',
                }}
              >
            
              <FeatherIcon icon="users" height="24" width="24" />
         
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default JumlahResponden;
