import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Card, CardContent, Box, Typography, Grid } from '@mui/material';

import PageContainer from '../../../../components/container/PageContainer';
import JumlahResponden from '../JumlahResponden';
import StatusLayanan from './StatusLayanan';


const DataChartLayanan = (props) => {
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState([]);
  
  useEffect(() => {
    if(props.data)
    {
      let i =0;
      let kuesioner = props.data.kuesioner;
      let category = [];
      let values = [
        {
          name: 'Tidak Baik',
          data: [],
        },
        {
          name: 'Cukup Baik',
          data: [],
        },
        {
          name: 'Baik',
          data: [],
        },
        {
          name: 'Sangat Baik',
          data: [],
        }
      ];
      for(i=0;i<kuesioner.length;i++)
      {
        category.push(kuesioner[i].kode);
        var responden = kuesioner[i].responden;
        var y =0;
        for(y=0;y<responden.length;y++)
        {
          var x = (responden[y].rating - 1);
          var jumlah = responden[y].jumlah;
          if(jumlah>0)
          {
            // console.log(kuesioner[i]);
            // console.log(responden[y]);
          }
          values[x].data.push(jumlah);
        }
      }
      setCategories(category);
      setData(values);
      //console.log(values);
    }
  }, [])
  
  
  const optionscolumnchart = {
    chart: {
      id: 'column-chart',
      fontFamily: "'DM Sans', sans-serif",
      foreColor: '#000000',
      toolbar: {
        show: true,
      },
    },
    colors: ['#6a00ff','#fec90f', '#00fffb', '#ff0d00'],
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: 'rounded',
        columnWidth: '20%',
      },
      
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: false,
      width: 2,
      colors: ['transparent'],
      
    },
    xaxis: {
      categories: categories,
     
    },
    
    
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter(val) {
          return `${val} Responden`;
        },
      },
      theme: 'dark',
    },
    grid: {
      show: false,
    },
    legend: {
      show: true,
      position: 'bottom',
      width: '50px',
    },
  };
  const seriescolumnchart = data;
  
  return (
    <PageContainer>
      {/* breadcrumb */}
      {/* end breadcrumb */}
      <Card >
        <Box p={2} display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h3">{(props.data) ? props.data.service_name : ''}</Typography>
          </Box>
        </Box>
        <Grid height={{xs:'100%'}} display={{xs:'block', lg:'flex'}} alignItems="center">
        
        {props.data &&
        <StatusLayanan data={props.data.skm} responden={props.data.responden}/>}
        
        
        
            <CardContent>
              <Chart 
                width={"100%"}
                series={seriescolumnchart}
                options={optionscolumnchart}
                type="bar"
                height="300px"
              />
             
        </CardContent>
        
        
      
        </Grid>
       
      </Card>
      
    </PageContainer>
  );
};

export default DataChartLayanan;
