import AuthHeader from "../helpers/auth_header";
import axios from "axios";

const get = async (url) => {
    
    return new Promise((resolve, reject) => {
        
        axios.get(url,{
            headers : AuthHeader(),
        })
        .then((response) => {
            //console.log(response);
            if(response.status===200)
            {
                resolve(response.data);
            }
            else{
                reject(false);   
            }
            
        })
        .catch((error) => {
            console.log(error);
            reject(false); 
        });
    });
}


const post = async (url,data) => {
    
    return new Promise((resolve, reject) => {
        
        axios.post(url,data,{
            headers : AuthHeader(),
        })
        .then((response) => {
            if(response.status===200)
            {
                resolve(response.data);
            }
            else{
                reject(false);   
            }
            
        })
        .catch((error) => {
            console.log(error);
            reject(false); 
        });
    });
}

const put = async (url,data) => {
    
    return new Promise((resolve, reject) => {
        
        axios.put(url,data,{
            headers : AuthHeader(),
        })
        .then((response) => {
            if(response.status===200)
            {
                resolve(response.data);
            }
            else{
                reject(false);   
            }
            
        })
        .catch((error) => {
            console.log(error);
            reject(false); 
        });
    });
}

const delete_ = async (url,data) => {
    
    return new Promise((resolve, reject) => {
        
        axios.delete(url,{
            headers : AuthHeader(),
        })
        .then((response) => {
            if(response.status===200)
            {
                resolve(response.data);
            }
            else{
                reject(false);   
            }
            
        })
        .catch((error) => {
            console.log(error);
            reject(false); 
        });
    });
}


export const WebServices = {
    get,
    post,
    put,
    delete : delete_
};
