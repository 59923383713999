import ApiConfig from "../../config/api";
import { WebServices } from "../WebServices";

const summary = async (params=null) => {
    let url = ApiConfig.SKM_REPORT_SUMMARY;
    if(params)
    {
        url += '?' + new URLSearchParams(params);
        //console.log(url);
    }
    return WebServices.get(url);
}

const services = async (params=null) => {
    let url = ApiConfig.SKM_REPORT_SERVICES;
    if(params)
    {
        url += '?' + new URLSearchParams(params);
        //console.log(url);
    }
    return WebServices.get(url);
}

export const Report = {
    summary,
    services
};
