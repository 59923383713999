import React, { Suspense } from 'react';
// import { render } from 'react-dom';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import { configureStore } from './redux/Store';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import Spinner from './views/spinner/Spinner';
import './assets/assets/css/bootstrap-blue.min.css';
import './assets/assets/css/app-blue.min.css';
import './assets/assets/css/icons.min.css';
import './assets/assets/libs/choices.js/public/assets/styles/choices.min.css';
import './assets/assets/libs/swiper/swiper-bundle.min.css';

const root = createRoot(
  // <Provider store={configureStore()}>
  //   <Suspense fallback={<Spinner />}>
  //     <BrowserRouter>
  //       <App />
  //     </BrowserRouter>
  //   </Suspense>
  // </Provider>,
  document.getElementById('root'),
  );
root.render(
  <Provider store={configureStore()}>
    <Suspense fallback={<Spinner />}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Suspense>
  </Provider>,
  );
// If you want to enable client cache, register instead.
serviceWorker.register();
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
