import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_FAILED } from "../constants";
import CryptoJs from 'crypto-js';
import config from './../../config/config';

let token = localStorage.getItem(config.access_token);
let byte = (token) ?  CryptoJs.AES.decrypt(token, config.secret_key) : null;
const user = (byte) ? JSON.parse(byte.toString(CryptoJs.enc.Utf8)) : null;

// const user = JSON.parse(localStorage.getItem("user"));

	const INIT_STATE = {
    is_login : (user) ? true : false,
    auth_data : (user) ? user : {},
    err_message : ''
};

const AuhReducer = (state = INIT_STATE, action) =>{

    switch(action.type){
        case AUTH_LOGIN :

            return {
                ...state,
                is_login : true,
                auth_data : action.auth_data
            }

        case AUTH_FAILED :

            return {
                ...state,
                is_login : false,
                auth_data : {},
                err_message : action.message
            }

        case AUTH_LOGOUT :
            return {
                ...state,
                is_login : false,
                auth_data : {}
            }

        default :
        return state
    }
}

export default AuhReducer;
