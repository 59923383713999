import CryptoJs from 'crypto-js';
import config from '../config/config';



const AuthHeader = () => {
    let token = localStorage.getItem(config.access_token);
    if(token)
    {
        let byte = CryptoJs.AES.decrypt(token, config.secret_key);
        const user = JSON.parse(byte.toString(CryptoJs.enc.Utf8));
    
        if(user && user.token)
        {
            return {
                'Authorization' : 'Bearer ' + user.token
            }
        }
    }
    else{
        return {};
    }
    }

export default AuthHeader;