import React from 'react';
import {
  Card,
  CardContent,
  Divider,
  Box,
  Typography,
  FormControlLabel,
  Button,
  Grid,
  RadioGroup,
  FormControl,
  MenuItem,
} from '@mui/material';
import CustomFormLabel from '../../../components/forms/custom-elements/CustomFormLabel';
import CustomRadio from '../../../components/forms/custom-elements/CustomRadio';
import Swal from 'sweetalert2';

import { useEffect, useState } from 'react';

import {Kuesioner} from '../../../models/skm/kuesioner.model';
import {Survey} from '../../../models/skm/survey.model';
import { useParams } from "react-router";

const FormSurvey = () => {

  const [kuesioner, setKuesioner] = useState([]);
  const [loading, setLoading] = useState(false);

  const {id} = useParams();

  useEffect(() => {
    getKuisioner();
  }, []);
  
  const getKuisioner = async () => {
    let param = {
      status : 'Y'
    }
    var result = await Kuesioner.get(param);

    // console.log(result);

    if (result.hasOwnProperty("status") && result.status == false && result.message == "Invalid token") {
      localStorage.removeItem("user");
      window.location = "/auth/login";
    }

    setKuesioner(result);

  }

  const onSelectOption = (i,rating) => {
    // console.log(rating);
    // console.log(i);

    let temp = kuesioner;
    temp[i].rating = rating;

    setKuesioner(temp);
    // console.log(kuesioner);
  }

  const onSubmit = async () => {
    setLoading(true);
    let param = {
      request : id,
      kuesioner : kuesioner
    }

    // console.log(param);

    let submit = await Survey.add(param);

    if(submit)
    {
      if(submit.status == true)
      {
        const Toast = Swal.mixin({
          toast: true,
          position: 'center',
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: false,
        })
        
        Toast.fire({
          icon: 'success',
          title: 'Terima kasih telah melakukan survey'
        });
        setLoading(false);
        setTimeout(() => {
          window.location = '/request/detail/' + id;
        }, 1500);
      }
      else{
        const Toast = Swal.mixin({
          toast: true,
          position: 'center',
          //showConfirmButton: false,
          //timer: 1500,
          timerProgressBar: false,
        })
        // console.log(submit);
        
        Toast.fire({
          icon: 'error',
          title: 'Gagal mengirim data'
        });
      }
    }
  }

  return (
    <div>
     
      <Card
        sx={{
          p: 0,
        }}
      >
        <Box
          sx={{
            padding: '15px 30px',
          }}
          display="flex"
          alignItems="center"
        >
          <Box marginLeft="20px" flexGrow={1}>
            <Typography fontWeight="500" variant="h4">
              Survey Kepuasan Masyarakat
            </Typography>
          </Box>
        </Box>
        <Divider />
        <CardContent
          sx={{
            padding: '30px',
            marginLeft:'20px'
          }}
        >
          <form>
          {kuesioner && kuesioner.map((item,x) => (
            <>
            <CustomFormLabel key={`nama_kuesioner_${x}`} variant="h4" htmlFor="readonly-text">{item.nama_kuesioner}</CustomFormLabel>      
            <Grid
              key={`grid_kuesioner_${x}`} 
              container
              spacing={0}
              sx={{
                mb: 2,
                mt: 2,
              }}
            >
              <Grid item lg={12} md={6} sm={12} key={`grid_kuesioner_2_${x}`} >
                <FormControl component="fieldset" key={`form_${x}`} >
                  <RadioGroup key={`radio_${x}`} 
                  >
                    {item.jawaban.map((option,i) => (
                      <>
                      <FormControlLabel
                        
                        key={`opsi_${i}`} 
                        value={option.rating}
                        control={<CustomRadio onClick={(e) => {onSelectOption(x,option.rating)}} />}
                        label={option.opsi}
                      />
                      </>
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            </>
          ))}
         
            
            

            <div>
              <Button disabled={loading} color="primary" variant="contained" onClick={(e) => {onSubmit()}}>
                Submit
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default FormSurvey;
