import { THEME_COLOR, NAVBAR_BG, SIDEBAR_BG, DIRECTION,  MODE_THEME } from '../constants';

const mode = (localStorage.getItem("mode")) ? localStorage.getItem("mode") : "light";
	const theme = (localStorage.getItem("theme")) ? localStorage.getItem("theme") : "PURPLE_THEME";

	const INIT_STATE = {
  activeDir: 'ltr',
  activeNavbarBg: '#0b70fb', // This can be any color,
  activeSidebarBg: '#ffffff', // This can be any color
  activeMode: mode, // This can be light or dark
  activeTheme: theme, // BLUE_THEME, GREEN_THEME, RED_THEME, BLACK_THEME, PURPLE_THEME, INDIGO_THEME
  SidebarWidth: 240,
};

const CustomizerReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case NAVBAR_BG:
      return {
        ...state,
        activeNavbarBg: action.payload,
      };
    case MODE_THEME:
      localStorage.setItem("mode",action.payload);
      return {
        ...state,
        activeMode: action.payload,
      };
    case SIDEBAR_BG:
      return {
        ...state,
        activeSidebarBg: action.payload,
      };
    case THEME_COLOR:
      localStorage.setItem("theme",action.payload);
      return {
        ...state,
        activeTheme: action.payload,
      };
    case DIRECTION:
      return {
        ...state,
        activeDir: action.payload,
      };

    default:
      return state;
  }
};

export default CustomizerReducer;
