import React from 'react';
import { Card, CardContent, Typography, Fab, Box, Avatar, useTheme } from '@mui/material';

import FeatherIcon from 'feather-icons-react';



const NilaiSkm = (props) => {

  // console.log(props);

  const theme = useTheme();
  const primary = theme.palette.primary.main;

return(
  <Card
   elevation={0}
    sx={{
      position: 'relative',
      backgroundColor: (theme) => `${theme.palette.mode === 'dark' ? '#32363e' : ''}`,
      '&:before': {
        content: `""`,
        position: 'absolute',
        left: (theme) => `${theme.direction === 'rtl' ? 'unset' : '0'}`,
        right: (theme) => `${theme.direction === 'rtl' ? '0' : 'unset'}`,
        width: '100%',
        height: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        transform: (theme) => `${theme.direction === 'rtl' ? 'scaleX(-1)' : 'unset'}`,
        backgroundPosition: (theme) =>
          `${theme.direction === 'rtl' ? 'right 19px center' : 'left 70px center'}`,
      },

      borderWidth: '0px',
    }}
  >
    <CardContent>
      <Box display="flex" alignItems="flex-start">
        <Box>
          <Typography
            variant="h3"
            color="textSecondary"
            sx={{
              color: '#000',
            }}
          >
            Nilai SKM
          </Typography>
        </Box>
        <Box
          sx={{
            ml: 'auto',
          }}
        >
          <Fab
            elevation="0"
            color="primary"
            aria-label="dollar"
            sx={{
              width: '48px',
              height: '48px',
              boxShadow: 'none',
            }}
          >
          <Typography variant='h2'>
            {(props.data) ? props.data.kategori : ''}
          </Typography>
          </Fab>
        </Box>
      </Box>
      
      <Typography
        fontWeight="500"
        variant="h1"
        sx={{
          color: '#000',
          mt: 3,
        }}
      >
        {(props.data) ? props.data.ikm : '0'} ({(props.data) ? props.data.kategori : ''})
      </Typography>
      <Typography
        variant="subtitle1"
        fontWeight="400"
        sx={{
          color: '#000',
          opacity: '0.5',
        }}
      >
       {(props.data) ? props.data.nama_kategori : ''}
      </Typography>
    </CardContent>
  </Card>
)
      };

export default NilaiSkm;
