import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full-layout/loadable/Loadable';
import FormSurvey from '../views/survey/isi_survey/FormSurvey';
import DashboardSkm from '../views/survey/recap_survei';
import Quisioner from '../views/survey/ref_quisioner';
import QuisionerForm from '../views/survey/ref_quisioner/QuisionerForm';
import QuisionerTambah from '../views/survey/ref_quisioner/QuisionerTambah';
import CryptoJs from 'crypto-js';
import config from './../config/config';


/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full-layout/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank-layout/BlankLayout')));
/* ***End Layouts**** */

// const Login = Loadable(lazy(() => import('../views/authentication/Login')));
const Login = Loadable(lazy(() => import('../components/Login')));
// const Register = Loadable(lazy(() => import('../views/authentication/register/')));
const Register = Loadable(lazy(() => import('../components/Singup')));
const Error = Loadable(lazy(() => import('../views/authentication/Error')));

/* ****Pages***** */
const Dashboard = Loadable(lazy(() => import('../views/dashboard')));
const DashboardUser = Loadable(lazy(() => import('../views/dashboard/DashboardUser')));

// user
const User = Loadable(lazy(() => import('../views/user')));

// service
const Services = Loadable(lazy(() => import('../views/master.data/service')));
const ServiceFlow = Loadable(lazy(() => import('../views/master.data/service/flow')));
const ServiceForm = Loadable(lazy(() => import('../views/master.data/service/form/')));
const ServiceFormBuilder = Loadable(lazy(() => import('../views/master.data/service/form/ServiceFormBuilder')));

// citizen
const Citizens = Loadable(lazy(() => import('../views/master.data/citizen')));
const CitizenForm = Loadable(lazy(() => import('../views/master.data/citizen/CitizenForm')));

// company
const Companies = Loadable(lazy(() => import('../views/master.data/company')));
const CompanyForm = Loadable(lazy(() => import('../views/master.data/company/CompanyForm')));

// school
const Schools = Loadable(lazy(() => import('../views/master.data/school')));
const SchoolForm = Loadable(lazy(() => import('../views/master.data/school/SchoolForm')));

// lpk
const Lpk = Loadable(lazy(() => import('../views/master.data/lpk')));
const LpkForm = Loadable(lazy(() => import('../views/master.data/lpk/LpkForm')));

// request
const Request = Loadable(lazy(() => import('../views/request')));
const RequestDetail = Loadable(lazy(() => import('../views/request/detail')));
const AddRequest = Loadable(lazy(() => import('../views/request/form/AddRequest')));
const EditRequest = Loadable(lazy(() => import('../views/request/form/EditRequest')));

// Job
const JobCategory = Loadable(lazy(() => import('../views/job/category')));
const JobVacancy = Loadable(lazy(() => import('../views/job/vacancy')));
const JobVacancyForm = Loadable(lazy(() => import('../views/job/vacancy/JobVacancyForm')));

//training
const Training = Loadable(lazy(() => import('../views/training')));
const TrainingForm = Loadable(lazy(() => import('../views/training/TrainingForm')));
/* ****Routes***** */




// const Landing = Loadable(lazy(() => import('../views/landing.page/index')));
const Landing = Loadable(lazy(() => import('../pages/LandingPage')));

// const Detail_loker = Loadable(lazy(() => import('../views/landing.page/InfoLoker/Detail_loker')));
const Detail_loker = Loadable(lazy(() => import('../pages/public/JobDetail')));
// const Detail_lokerMap = Loadable(lazy(() => import('../views/landing.page/InfoLoker/Detail_lokerMap')));
const Detail_lokerMap = Loadable(lazy(() => import('../pages/public/CompanyDetail')));
// const Loker_page = Loadable(lazy(() => import('../views/landing.page/InfoLoker/Loker_page')));
const Loker_page = Loadable(lazy(() => import('../pages/public/Job/Jobs')));


// const Cari_pelatihan = Loadable(lazy(() => import('../views/landing.page/ProgramPelatihan/Cari_pelatihan')));
const Cari_pelatihan = Loadable(lazy(() => import('../pages/public/Training/CariTraining')));
// const Detail_pelatihan = Loadable(lazy(() => import('../views/landing.page/ProgramPelatihan/Detail_pelatihan')));
const Detail_pelatihan = Loadable(lazy(() => import('../pages/public/TrainingDetail')));


// const Cari_layanan = Loadable(lazy(() => import('../views/landing.page/SuratAdministrasi/Cari_layanan')));
const Cari_layanan = Loadable(lazy(() => import('../pages/public/Services')));
// const Detail_layanan = Loadable(lazy(() => import('../views/landing.page/SuratAdministrasi/Detail_layanan')));
const Detail_layanan = Loadable(lazy(() => import('../pages/public/Service/ServiceDetail')));

const Status = Loadable(lazy(() => import('../views/request/status/Status')));



let token = localStorage.getItem(config.access_token);
let byte = (token) ?  CryptoJs.AES.decrypt(token, config.secret_key) : null;
const user = (byte) ? JSON.parse(byte.toString(CryptoJs.enc.Utf8)) : null;
// const user = JSON.parse(localStorage.getItem("user"));

let Router = [
  {
    path: '/',
    element: <Landing />,

  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: '*', element: <Navigate to="/error/404" /> },
    ],
  },
  {
    path: '/error',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: '*', element: <Navigate to="/error/404" /> },
    ],
  },
  {
    path: '/home',
    element: <Landing />,

  },
  {
    path: '/job-list',
    element: <Loker_page />,


  },
  {
    path: '/job-detail/:id', exact: true,
    element: <Detail_loker />,


  },
  {
    path: '/company-detail/:id', exact: true,
    element: <Detail_lokerMap />,


  },
  {
    path: '/training', exact: true,
    element: <Cari_pelatihan />,


  },
  {
    path: '/training/:id', exact: true,
    element: <Detail_pelatihan />,


  },
  {
    path: '/services',
    element: <Cari_layanan />,


  },
  {
    path: '/service/:id',exact: true,
    element: <Detail_layanan />,
  },

  {
    path:'/job-list/:value', element: <Loker_page/> 
  },

  {
    path: '/status/:id',exact: true,
    element: <Status />,
  },
  { path: '*', element: <Navigate to="/error/404" /> },
  ];



  if(user && user.role==="admin")
{
  Router[0] = 
    {
      path: '/',
      element: <FullLayout />,
      children: [

        { path: '/dashboard', exact: true, element: <Dashboard /> },
        { path: '/users', exact: true, element: <User /> },
        { path: '/services', exact: true, element: <Services /> },
        { path: '/service/form/:service_id', element: <ServiceForm /> },
        { path: '/service/form/add/:service_id', element: <ServiceFormBuilder /> },
        { path: '/service/form/edit/:service_id/:token', element: <ServiceFormBuilder /> },
        { path: '/service/flow/:service_id', element: <ServiceFlow /> },

        { path: '/citizens', exact: true, element: <Citizens /> },
        { path: '/citizen/add', exact: true, element: <CitizenForm /> },
        { path: '/citizen/edit/:id', exact: true, element: <CitizenForm /> },

        { path: '/companies', exact: true, element: <Companies /> },
        { path: '/company/add', exact: true, element: <CompanyForm /> },
        { path: '/company/edit/:id', exact: true, element: <CompanyForm /> },

        { path: '/schools', exact: true, element: <Schools /> },
        { path: '/school/add', exact: true, element: <SchoolForm /> },
        { path: '/school/edit/:id', exact: true, element: <SchoolForm /> },


        { path: '/lpk', exact: true, element: <Lpk /> },
        { path: '/lpk/add', exact: true, element: <LpkForm /> },
        { path: '/lpk/edit/:id', exact: true, element: <LpkForm /> },


        //{ path: '/requests', exact : true, element : <Request /> },
        { path: '/request/detail/:id', exact : true, element : <RequestDetail /> },
        { path: '/request/add/:service_id', exact : true, element : <AddRequest /> },
        { path: '/request/edit/:id', exact : true, element : <EditRequest /> },

        { path: '/surat-administrasi', exact : true, element : <Request type="request" /> },
        { path: '/pelaporan-data', exact : true, element : <Request type="report" /> },

        // job 
        { path: '/job/vacancy', exact : true, element : <JobVacancy /> },
        { path: '/job/vacancy/add', exact : true, element : <JobVacancyForm /> },
        { path: '/job/vacancy/edit/:id', exact : true, element : <JobVacancyForm /> },
        { path: '/job/category', exact : true, element : <JobCategory /> },

        // training
        { path: '/training', exact : true, element : <Training /> },
        { path: '/training/add', exact : true, element : <TrainingForm /> },
        { path: '/training/edit/:id', exact : true, element : <TrainingForm /> },

        // Dashboard SKM
        { path: '/Dashboard_skm', exact : true, element : <DashboardSkm /> },


        // survey
        { path: '/ref_quisioner', exact : true, element : <Quisioner /> },
        { path: '/quisioner/add', exact : true, element : <QuisionerTambah /> },
        { path: '/quisioner/edit/:id', exact : true, element : <QuisionerForm /> },

        // isi survey
        { path: '/skm/survey/:id', exact : true, element : <FormSurvey /> },


        { path: '*', element: <Navigate to="/error/404" /> },
      ],
    };
    }

    if(user && user.role==="officer")
{
  Router[0] = 
    {
      path: '/',
      element: <FullLayout />,
      children: [
        { path: '/dashboard', exact: true, element: <Dashboard /> },

        { path: '/citizens', exact: true, element: <Citizens /> },
        { path: '/citizen/add', exact: true, element: <CitizenForm /> },
        { path: '/citizen/edit/:id', exact: true, element: <CitizenForm /> },

        { path: '/companies', exact: true, element: <Companies /> },
        { path: '/company/add', exact: true, element: <CompanyForm /> },
        { path: '/company/edit/:id', exact: true, element: <CompanyForm /> },

        { path: '/schools', exact: true, element: <Schools /> },
        { path: '/school/add', exact: true, element: <SchoolForm /> },
        { path: '/school/edit/:id', exact: true, element: <SchoolForm /> },

        { path: '/request/detail/:id', exact : true, element : <RequestDetail /> },
        { path: '/request/add/:service_id', exact : true, element : <AddRequest /> },
        { path: '/request/edit/:id', exact : true, element : <EditRequest /> },

        { path: '/surat-administrasi', exact : true, element : <Request type="request" /> },
        { path: '/pelaporan-data', exact : true, element : <Request type="report" /> },

        { path: '/lpk', exact: true, element: <Lpk /> },
        { path: '/lpk/add', exact: true, element: <LpkForm /> },
        { path: '/lpk/edit/:id', exact: true, element: <LpkForm /> },

        // job 
        { path: '/job/vacancy', exact : true, element : <JobVacancy /> },
        { path: '/job/vacancy/add', exact : true, element : <JobVacancyForm /> },
        { path: '/job/vacancy/edit/:id', exact : true, element : <JobVacancyForm /> },
        { path: '/job/category', exact : true, element : <JobCategory /> },

        // training
        { path: '/training', exact : true, element : <Training /> },
        { path: '/training/add', exact : true, element : <TrainingForm /> },
        { path: '/training/edit/:id', exact : true, element : <TrainingForm /> },

        { path: '*', element: <Navigate to="/error/404" /> },
      ],
    };
    }

    if(user && user.role==="company")
{
  Router[0] = 
    {
      path: '/',
      element: <FullLayout />,
      children: [
        { path: '/dashboard', exact: true, element: <DashboardUser /> },

        { path: '/request/detail/:id', exact : true, element : <RequestDetail /> },
        { path: '/request/add/:service_id', exact : true, element : <AddRequest /> },
        { path: '/request/edit/:id', exact : true, element : <EditRequest /> },

        { path: '/surat-administrasi', exact : true, element : <Request type="request" /> },
        { path: '/pelaporan-data', exact : true, element : <Request type="report" /> },

        { path: '*', element: <Navigate to="/error/404" /> },

        // job 
        { path: '/job/vacancy', exact : true, element : <JobVacancy /> },
        { path: '/job/vacancy/add', exact : true, element : <JobVacancyForm /> },
        { path: '/job/vacancy/edit/:id', exact : true, element : <JobVacancyForm /> },

        // training
        { path: '/training', exact : true, element : <Training /> },
        { path: '/training/add', exact : true, element : <TrainingForm /> },
        { path: '/training/edit/:id', exact : true, element : <TrainingForm /> },
      ],
    };
    }

    if(user && user.role==="school")
{
  Router[0] = 
    {
      path: '/',
      element: <FullLayout />,
      children: [
        { path: '/dashboard', exact: true, element: <DashboardUser /> },

        { path: '/request/detail/:id', exact : true, element : <RequestDetail /> },
        { path: '/request/add/:service_id', exact : true, element : <AddRequest /> },
        { path: '/request/edit/:id', exact : true, element : <EditRequest /> },

        { path: '/surat-administrasi', exact : true, element : <Request type="request" /> },
        { path: '/pelaporan-data', exact : true, element : <Request type="report" /> },

        { path: '*', element: <Navigate to="/error/404" /> },
      ],
    };
    }

    if(user && user.role==="lpk")
{
  Router[0] = 
    {
      path: '/',
      element: <FullLayout />,
      children: [
        { path: '/dashboard', exact: true, element: <DashboardUser /> },

        { path: '/request/detail/:id', exact : true, element : <RequestDetail /> },
        { path: '/request/add/:service_id', exact : true, element : <AddRequest /> },
        { path: '/request/edit/:id', exact : true, element : <EditRequest /> },

        { path: '/surat-administrasi', exact : true, element : <Request type="request" /> },
        { path: '/pelaporan-data', exact : true, element : <Request type="report" /> },

        // training
        { path: '/training', exact : true, element : <Training /> },
        { path: '/training/add', exact : true, element : <TrainingForm /> },
        { path: '/training/edit/:id', exact : true, element : <TrainingForm /> },

        { path: '*', element: <Navigate to="/error/404" /> },
      ],
    };
    }

    if(user && user.role==="citizen")
{
  Router[0] = 
    {
      path: '/',
      element: <FullLayout />,
      children: [
        { path: '/dashboard', exact: true, element: <DashboardUser /> },

        { path: '/request/detail/:id', exact : true, element : <RequestDetail /> },
        { path: '/request/add/:service_id', exact : true, element : <AddRequest /> },
        { path: '/request/edit/:id', exact : true, element : <EditRequest /> },

        { path: '/surat-administrasi', exact : true, element : <Request type="request" /> },
        /* { path: '/pelaporan-data', exact : true, element : <Request type="report" /> }, */
        { path: '/skm/survey/:id', exact : true, element : <FormSurvey /> },

        { path: '*', element: <Navigate to="/error/404" /> },
      ],
    };
    }


//console.log(Router);
if(!user && Router[0] && Router[0].hasOwnProperty("children"))
{
  for(var i=0; i < Router[0].children.length; i++)
  {
    Router[0].children[i].element = <Navigate to="/auth/login" />;
  }
  }

export default Router;
