import {
    Card,
    CardContent,
    Grid,
    MenuItem,
  
    Button,
    Box,
    Select,
    TextField,
    FormControl,
    InputLabel,
  } from "@mui/material";
  
  import { Link } from "react-router-dom";
  
  
  import Breadcrumb from "../../../layouts/full-layout/breadcrumb/Breadcrumb";
  import PageContainer from "../../../components/container/PageContainer";
  import { useEffect, useState } from "react";
  
  import Sweetalert from "sweetalert2";
  import withReactContent from "sweetalert2-react-content";
  
  import { useNavigate, useParams } from "react-router";
  
  import FeatherIcon from "feather-icons-react";
  import CustomFormLabel from "../../../components/forms/custom-elements/CustomFormLabel";
  import CustomTextField from "../../../components/forms/custom-elements/CustomTextField";
  import CustomSelect from "../../../components/forms/custom-elements/CustomSelect";
  import Globalvar from "../../../config/globalvar.json";
  import axios from "axios";
  import { data } from "jquery";
  import { el } from "date-fns/locale";
import ApiConfig from "config/api";
import { useSelector } from "react-redux";
  
  
  const Swal = withReactContent(Sweetalert);
  // let user = JSON.parse(localStorage.getItem("user"));
  // const user = useSelector((state) => state.AuthReducer.auth_data);
  // console.log('user Token: '+ user.token);
  // let token2 = localStorage.getItem("token");
  // console.log(token2);
  // let token =
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2MmM1NTJkYzhlYzZkMjM5N2Y3NjhjMGMiLCJpYXQiOjE2NjI2MzQ0OTEsImV4cCI6MTY2MzIzOTI5MX0.wCL9wWaAO_GhcsbbtXnPcV7bFCTZP9T_3Zp7wujwgqo";
  
  
  const QuisionerTambah = () => {
    const user = useSelector((state) => state.AuthReducer.auth_data);
    // console.log('user Token: '+ user.token);
    const title =  "Tambah Survey";
  
    const BCrumb = [
      {
        to: "/",
        title: "Home",
      },
      {
        to: "/ref_quisioner",
        title: "Survey",
      },
    ];
  
    const [jawaban, setJawaban] = useState();
    const [status, setStatus] = useState("");
    const [nama_kuesioner, setPertanyaan] = useState("");
    const [kode, setKode] = useState("");
  const navigate = useNavigate();
  
   
  
    const saveUser = async (e) => {
      e.preventDefault();
    try {
      
        const response = await axios.post(
          // "https://api-begaweoi.oganilirkab.go.id/v1/skm/kuesioner/add",
          `${ApiConfig.WEB_SERVER}/v1/skm/kuesioner/add`,
  
          {
            nama_kuesioner,
            kode,
            status,
            jawaban,
          },
          {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              "Authorization": "Bearer " + user.token,
            },
          }
        )
          // let jsonData = JSON.stringify(response);
          // console.log(jsonData);
          // console.log("data berhasil dikirim");
          const Toast = Swal.mixin({
            toast: true,
            position: 'center',
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: false,
          })
          
          Toast.fire({
            icon: 'success',
            title: "data berhasil si simpan"
          });
  
          setTimeout(() => {
            navigate('/ref_quisioner');
          }, 1500);
      
  
      
      } catch (error) {
      // console.log(error);
      }
    };
  
  
    useEffect(() => {
      setJawaban([
        {
          opsi: "Sangat puas",
          rating: "4",
        },
        {
          opsi: "Puas",
          rating: "3",
        },
        {
          opsi: "Cukup",
          rating: "2",
        },
        {
          opsi: "Kurang",
          rating: "1",
        },
      ]);
    }, []);
  
    const onChangeOpsi = (e, i) => {
      let temp = jawaban;
      temp[i].opsi = e.target.value;
      // setJawaban(temp);
      
    };
  // console.log(status);
  
  
  
    return (
      <PageContainer title={title} description={title}>
        <Box
          sx={{
            display: {
              xs: "block",
              sm: "flex",
              lg: "flex",
            },
            alignItems: "center",
            mt: 3,
          }}
        >
          {/* breadcrumb */}
          <Breadcrumb title={title} items={BCrumb} />
          {/* end breadcrumb */}
        </Box>
  
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12} sm={12}>
                {/* <CustomFormLabel>Nama Kode</CustomFormLabel>
                <CustomTextField
                  value={kode}
                  onChange={(e) => setKode(e.target.value)}
                  id="kode"
                  name="kode"
                  placeholder="Masukan Nama Kode"
                  variant="outlined"
                  fullWidth
                  size="small"
                ></CustomTextField> */}
                <TextField
                  id="kode"
                  name="kode"
                  label="Kode"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={kode}
                  onChange={(e) => setKode(e.target.value)}
                />
              </Grid>
  
              <Grid item xs={12} lg={12} sm={12}>
                {/* <CustomFormLabel>Pertanyaan</CustomFormLabel>
                <CustomTextField
                  value={nama_kuesioner}
                  onChange={(e) => setPertanyaan(e.target.value)}
                  id="nama_kuesioner"
                  name="nama_kuesioner"
                  placeholder="Masukan Pertanyaan"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  size="small"
                ></CustomTextField> */}
                <TextField
                  id="nama_kuesioner"
                  name="nama_kuesioner"
                  label="Pertanyaan"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={nama_kuesioner}
                  onChange={(e) => setPertanyaan(e.target.value)}
                  multiline
                  rows={4}
                />
              </Grid>
  
              <Grid item xs={12} lg={9} sm={12}>
                <Grid item xs={12} lg={12} sm={6}>
                  {/* <CustomFormLabel>Opsi A</CustomFormLabel>
  
                  <CustomTextField
                   onChange={(e) => {
                      onChangeOpsi(e, 0);
                    }}
                    id="opsi"
                    name="opsi"
                    placeholder="Cth. Sangat Sesuai"
                    variant="outlined"
                    fullWidth
                    size="small"
                  ></CustomTextField> */}
                  <TextField
                    sx={ { mt: 1}}
                    id="opsi"
                    name="opsi"
                    label="Opsi A"
                    variant="outlined"
                    fullWidth
                    size="small"
                    onChange={(e) => {
                      onChangeOpsi(e, 0);
                    }}
                  />
                </Grid>
  
                <Grid item xs={12} lg={12} sm={6}>
                  {/* <CustomFormLabel>Opsi B</CustomFormLabel>
                  <CustomTextField
                  onChange={(e) => {
                      onChangeOpsi(e, 1);
                    }}
                    id="opsi"
                    name="opsi"
                    placeholder="Cth. Sesuai"
                    variant="outlined"
                    fullWidth
                    size="small"
                  ></CustomTextField> */}
                  <TextField
                    sx={ { mt: 1}}
                    id="opsi"
                    name="opsi"
                    label="Opsi B"
                    variant="outlined"
                    fullWidth
                    size="small"
                    onChange={(e) => {
                      onChangeOpsi(e, 1);
                    }}
                  />
                </Grid>
  
                <Grid item xs={12} lg={12} sm={6}>
                  {/* <CustomFormLabel>Opsi C</CustomFormLabel>
                  <CustomTextField
                    onChange={(e) => {
                      onChangeOpsi(e, 2);
                    }}
                    
                    id="opsi"
                    name="opsi"
                    placeholder="Cth. Kurang Sesuai"
                    variant="outlined"
                    fullWidth
                    size="small"
                  ></CustomTextField> */}
                  <TextField
                    sx={ { mt: 1}}
                    id="opsi"
                    name="opsi"
                    label="Opsi C"
                    variant="outlined"
                    fullWidth
                    size="small"
                    onChange={(e) => {
                      onChangeOpsi(e, 2);
                    }}
                  />
                </Grid>
  
                <Grid item xs={12} lg={12} sm={6}>
                  {/* <CustomFormLabel>Opsi D</CustomFormLabel>
                  <CustomTextField
                     onChange={(e) => {
                      onChangeOpsi(e, 3);
                    }}
                    id="opsi"
                    name="opsi"
                    placeholder="Cth. Tidak Sesuai"
                    variant="outlined"
                    fullWidth
                    size="small"
                  ></CustomTextField> */}
                  <TextField
                    sx={ { mt: 1}}
                    id="opsi"
                    name="opsi"
                    label="Opsi D"
                    variant="outlined"
                    fullWidth
                    size="small"
                    onChange={(e) => {
                      onChangeOpsi(e, 3);
                    }}
                  />
                </Grid>
              </Grid>
  
              <Grid item xs={12} lg={3} sm={6}>
                {/* <CustomFormLabel>Status</CustomFormLabel>
                <CustomSelect
                 onChange={(e) => setStatus(e.target.value)}
                 value={status}
                  id="status"
                  name="status"
                  fullWidth
                  size="small"
                  variant="outlined"
                >
                  <MenuItem key="0" >
                     Pilih
                  </MenuItem>
                 
                    <MenuItem key="Aktif"  value="Y">
                     Aktif
                    </MenuItem>
                    <MenuItem key="Non-Aktif" value="N">
                  Non-Aktif
                    </MenuItem>
                </CustomSelect> */}
                <FormControl
                  variant="outlined"
                  fullWidth
                  size="small"
                  
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    label="Status"
                  >
                    <MenuItem key="0" value="">
                      <em>Pilih</em>
                    </MenuItem>
                    <MenuItem key="Aktif" value="Y">
                      Aktif
                    </MenuItem>
                    <MenuItem key="Non-Aktif" value="N">
                      Non-Aktif
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
  
            <Box paddingRight={2} style={{ marginTop: "30px" }}>
          
              <Button
                variant="contained"
                component={Link}
                to="/ref_quisioner"
                style={{ borderRadius: "20px"}}
                color="secondary"
                startIcon={<FeatherIcon icon="chevron-left" />}
              >
                Kembali
              </Button>
              <Button
                onClick={saveUser}
                variant="contained"
                style={{ borderRadius: "20px", marginLeft: "5px"  }}
                color="primary"
                startIcon={<FeatherIcon icon="check-circle" />}
              >
                Simpan
              </Button>
            </Box>
          </CardContent>
        </Card>
      </PageContainer>
    );
  };
  
  export default QuisionerTambah;
  