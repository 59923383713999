import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Card, CardContent, Box, Typography, Paper, TableContainer, Table } from '@mui/material';

import PageContainer from '../../../components/container/PageContainer';


const ChartPerBulan = (props) => {
  const [label, setLabel] = useState([]);
  const [value, setValue] = useState([]);
  
  useEffect(() => {
    let labels = [];
    let values = [];
    if(props.data)
    {
      let monthly = props.data.monthly;
      let i=0;
      for(i=0;i<monthly.length;i++)
      {
        labels.push(monthly[i].bulan_desc);
        values.push(monthly[i].jumlah);
      }
    }
  
    setLabel(labels);
    setValue(values);
  }, [props])
  
  const optionscolumnchart = {
    chart: {
      id: 'column-chart',
      fontFamily: "'DM Sans', sans-serif",
      foreColor: '#0077ff',
      toolbar: {
        show: false,
      },
    },
    colors: ['#0077ff'],
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: 'rounded',
        columnWidth: '50%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: label,
    },
    yaxis: {
      title: {
        text: '(Dalam Setahun)',
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter(val) {
          return `${val} org`;
        },
      },
      theme: 'dark',
    },
    grid: {
      show: false,
    },
    legend: {
      show: true,
      position: 'bottom',
      width: '50px',
    },
  };
  const seriescolumnchart = [
    {
      name: 'Responden',
      data: value,
    },
    
  ];
  
  
  
  
  
  return (
    <PageContainer title="Recap Survey" description="this is innerpage">
      {/* breadcrumb */}
      {/* end breadcrumb */}
      <TableContainer sx={{ maxHeight: '100%' }}>
        <Table stickyHeader aria-label="sticky table">
      <Card >
      
        <Box p={2} display="flex" alignItems="center" stickyHeader aria-label="sticky table">
          <Box flexGrow={1}>
            <Typography variant="h4">Jumlah Responden</Typography>
          </Box>
        </Box>
        <CardContent sx={{ width: '100%' }}>
          <Chart
            options={optionscolumnchart}
            series={seriescolumnchart}
            type="bar"
            height="300px"
          />
        </CardContent>
      </Card>
      
      </Table>
      </TableContainer>
    </PageContainer>
  );
};

export default ChartPerBulan;
