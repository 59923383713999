// const WEB_SERVER = "https://c0ac-103-162-35-26.ngrok-free.app";
// const WEB_SERVER = "http://localhost:3003";
const WEB_SERVER = "https://api-gaweoi.oganilirkab.go.id";

const USER_LOGIN = WEB_SERVER + '/v1/user/login';
const USER_REGISTER = WEB_SERVER + '/v1/user/register';
const USER_GET = WEB_SERVER + '/v1/user';
const USER_DETAIL = WEB_SERVER + '/v1/user';
const USER_EDIT = WEB_SERVER + '/v1/user';
const USER_DELETE = WEB_SERVER + '/v1/user';

const SERVICE_ADD = WEB_SERVER + '/v1/service/add';
const SERVICE_EDIT = WEB_SERVER + '/v1/service';
const SERVICE_DELETE = WEB_SERVER + '/v1/service';
const SERVICE_GET = WEB_SERVER + '/v1/service';
const SERVICE_GET_PUBLIC = WEB_SERVER + '/v1/public/service';
const SERVICE_TEMPLATE = WEB_SERVER + '/v1/service/template';

const SERVICE_FORM_ADD = WEB_SERVER + '/v1/service/form';
const SERVICE_FORM_EDIT = WEB_SERVER + '/v1/service/form';
const SERVICE_FORM_DELETE = WEB_SERVER + '/v1/service/form/delete';

const SERVICE_FLOW_ADD = WEB_SERVER + '/v1/service/flow/add';
const SERVICE_FLOW_EDIT = WEB_SERVER + '/v1/service/flow';
const SERVICE_FLOW_DELETE = WEB_SERVER + '/v1/service/flow';
const SERVICE_FLOW_GET = WEB_SERVER + '/v1/service/flow';
const SERVICE_FLOW_DETAIL = WEB_SERVER + '/v1/service/flow';

const TASK_GET = WEB_SERVER + '/v1/task';
const TASK_UPDATE = WEB_SERVER + '/v1/task/update';
const TASK_REJECT = WEB_SERVER + '/v1/task/reject';

const REQUEST_GET = WEB_SERVER + '/v1/request';
const REQUEST_STATUS = WEB_SERVER + '/v1/request/status';
const REQUEST_ADD = WEB_SERVER + '/v1/request/add';
const REQUEST_EDIT = WEB_SERVER + '/v1/request';
const REQUEST_UPLOAD = WEB_SERVER + '/v1/request/upload';
const REQUEST_FORM_DOWNLOAD = WEB_SERVER + '/v1/request/form/download';
const REQUEST_TOTAL_STATUS = WEB_SERVER + '/v1/request/total/status';
const REQUEST_TOTAL_SERVICE = WEB_SERVER + '/v1/request/total/service';
const REQUEST_TOTAL_MONTH = WEB_SERVER + '/v1/request/total/month';
const REQUEST_TOTAL_YEAR = WEB_SERVER + '/v1/request/total/year';
const REQUEST_AVG_MONTH = WEB_SERVER + '/v1/request/avg/month';
const REQUEST_DETAIL = WEB_SERVER + '/v1/public/request';

const REQUEST_CHANGE_STATUS_PROCESSING = WEB_SERVER + '/v1/request/status/processing';
const REQUEST_CHANGE_STATUS_CANCELED = WEB_SERVER + '/v1/request/status/canceled';

const AREA_PROVINCE = WEB_SERVER + '/v1/area/province';
const AREA_REGENCY = WEB_SERVER + '/v1/area/regency/province';
const AREA_DISTRICT = WEB_SERVER + '/v1/area/district/regency';
const AREA_VILLAGE = WEB_SERVER + '/v1/area/village/district';

const CITIZEN_GET = WEB_SERVER + '/v1/citizen';
const CITIZEN_GET_NIK = WEB_SERVER + '/v1/citizen/nik';
const CITIZEN_ADD = WEB_SERVER + '/v1/citizen/add';
const CITIZEN_EDIT = WEB_SERVER + '/v1/citizen';
const CITIZEN_DELETE = WEB_SERVER + '/v1/citizen';


//const COMPANY_GET = WEB_SERVER + '/v1/company';
const COMPANY_GET = WEB_SERVER + '/v1/public/company';
const COMPANY_TYPE = WEB_SERVER + '/v1/company/type';
const COMPANY_ADD = WEB_SERVER + '/v1/company/add';
const COMPANY_EDIT = WEB_SERVER + '/v1/company';
const COMPANY_DELETE = WEB_SERVER + '/v1/company';
const COMPANY_UPLOAD_LOGO = WEB_SERVER + '/v1/company/upload_logo';

const SCHOOL_GET = WEB_SERVER + '/v1/school';
const SCHOOL_ADD = WEB_SERVER + '/v1/school/add';
const SCHOOL_EDIT = WEB_SERVER + '/v1/school';
const SCHOOL_DELETE = WEB_SERVER + '/v1/school';

const LPK_GET = WEB_SERVER + '/v1/lpk';
const LPK_ADD = WEB_SERVER + '/v1/lpk/add';
const LPK_EDIT = WEB_SERVER + '/v1/lpk';
const LPK_DELETE = WEB_SERVER + '/v1/lpk';

//const JOB_CATEGORY_GET = WEB_SERVER + '/v1/job/category';
const JOB_CATEGORY_GET = WEB_SERVER + '/v1/public/job/category';
const JOB_CATEGORY_ADD = WEB_SERVER + '/v1/job/category/add';
const JOB_CATEGORY_EDIT = WEB_SERVER + '/v1/job/category';
const JOB_CATEGORY_DELETE = WEB_SERVER + '/v1/job/category';

//const JOB_VACANCY_GET = WEB_SERVER + '/v1/job/vacancy';
const JOB_VACANCY_GET = WEB_SERVER + '/v1/public/job/vacancy';
const JOB_VACANCY_ADD = WEB_SERVER + '/v1/job/vacancy/add';
const JOB_VACANCY_EDIT = WEB_SERVER + '/v1/job/vacancy';
const JOB_VACANCY_DELETE = WEB_SERVER + '/v1/job/vacancy';

//const TRAINING_GET = WEB_SERVER + '/v1/training';
const TRAINING_GET = WEB_SERVER + '/v1/public/training';
const TRAINING_ADD = WEB_SERVER + '/v1/training/add';
const TRAINING_EDIT = WEB_SERVER + '/v1/training';
const TRAINING_DELETE = WEB_SERVER + '/v1/training';
const TRAINING_UPLOAD_IMAGE = WEB_SERVER + '/v1/training/upload_image';

const SUMMARY_TOTAL = WEB_SERVER + '/v1/summary/total';
const SUMMARY_PERFORMANCE = WEB_SERVER + '/v1/summary/performance';

// SKM
const SKM_KUISIONER = WEB_SERVER + '/v1/skm/kuesioner'; // method = get
const SKM_KUISIONER_ADD = WEB_SERVER + '/v1/skm/kuesioner/add'; // method = post
const SKM_KUISIONER_EDIT = WEB_SERVER + '/v1/skm/kuesioner'; // method = put; url/id
const SKM_KUISIONER_DELETE = WEB_SERVER + '/v1/skm/kuesioner'; // method = delete; url/id

const SKM_SURVEY = WEB_SERVER + '/v1/skm/survey'; 
const SKM_SURVEY_ADD = WEB_SERVER + '/v1/skm/survey/add'; 
const SKM_SURVEY_DELETE = WEB_SERVER + '/v1/skm/survey'; 

const SKM_REPORT_SUMMARY = WEB_SERVER + '/v1/skm/report/summary'; 
const SKM_REPORT_SERVICES = WEB_SERVER + '/v1/skm/report/services'; 

const ApiConfig = {
    WEB_SERVER,
    USER_LOGIN,
    USER_REGISTER,
    USER_GET,
    USER_DETAIL,
    USER_EDIT,
    USER_DELETE,

    SERVICE_ADD,
    SERVICE_EDIT,
    SERVICE_DELETE,
    SERVICE_GET,
    SERVICE_GET_PUBLIC,
    SERVICE_TEMPLATE,

    SERVICE_FORM_ADD,
    SERVICE_FORM_EDIT,
    SERVICE_FORM_DELETE,

    SERVICE_FLOW_ADD,
    SERVICE_FLOW_EDIT,
    SERVICE_FLOW_DELETE,
    SERVICE_FLOW_GET,
    SERVICE_FLOW_DETAIL,

    TASK_GET,
    TASK_UPDATE,
    TASK_REJECT,

    REQUEST_GET,
    REQUEST_STATUS,
    REQUEST_ADD,
    REQUEST_EDIT,
    REQUEST_UPLOAD,
    REQUEST_FORM_DOWNLOAD,
    REQUEST_TOTAL_STATUS,
    REQUEST_TOTAL_SERVICE,
    REQUEST_TOTAL_MONTH,
    REQUEST_TOTAL_YEAR,
    REQUEST_AVG_MONTH,
    REQUEST_DETAIL,

    REQUEST_CHANGE_STATUS_PROCESSING,
    REQUEST_CHANGE_STATUS_CANCELED,

    AREA_PROVINCE,
    AREA_REGENCY,
    AREA_DISTRICT,
    AREA_VILLAGE,

    CITIZEN_GET,
    CITIZEN_DELETE,
    CITIZEN_ADD,
    CITIZEN_EDIT,
    CITIZEN_GET_NIK,

    COMPANY_GET,
    COMPANY_TYPE,
    COMPANY_ADD,
    COMPANY_EDIT,
    COMPANY_DELETE,
    COMPANY_UPLOAD_LOGO,

    SCHOOL_GET,
    SCHOOL_ADD,
    SCHOOL_EDIT,
    SCHOOL_DELETE,

    LPK_GET,
    LPK_ADD,
    LPK_EDIT,
    LPK_DELETE,

    JOB_CATEGORY_GET,
    JOB_CATEGORY_ADD,
    JOB_CATEGORY_EDIT,
    JOB_CATEGORY_DELETE,

    JOB_VACANCY_GET,
    JOB_VACANCY_ADD,
    JOB_VACANCY_EDIT,
    JOB_VACANCY_DELETE,

    TRAINING_GET,
    TRAINING_ADD,
    TRAINING_EDIT,
    TRAINING_DELETE,
    TRAINING_UPLOAD_IMAGE,

    SUMMARY_TOTAL,
    SUMMARY_PERFORMANCE,

    // skm
    SKM_KUISIONER,
    SKM_KUISIONER_ADD,
    SKM_KUISIONER_EDIT,
    SKM_KUISIONER_DELETE,

    SKM_SURVEY,
    SKM_SURVEY_ADD,
    SKM_SURVEY_DELETE,

    SKM_REPORT_SUMMARY,
    SKM_REPORT_SERVICES
    }

export default ApiConfig;
