import ApiConfig from "../../config/api";
import { WebServices } from "../WebServices";

const get = async (params=null) => {
    let url = ApiConfig.SKM_SURVEY;
    if(params)
    {
        url += '?' + new URLSearchParams(params);
        //console.log(url);
    }
    return WebServices.get(url);
}


const getDetail = async (id) => {
    let url = ApiConfig.SKM_SURVEY + '/' + id; 
    return WebServices.get(url);
}

const add = async (data) => {
    let url = ApiConfig.SKM_SURVEY_ADD;
    return WebServices.post(url,data);
}


const _delete = async (id) => {
    let url = ApiConfig.SKM_SURVEY_DELETE + '/' + id;
    return WebServices.delete(url);
}


export const Survey = {
    get,
    getDetail,
    add,
    delete : _delete
};
