import React from 'react';
import {
  Card,
  CardContent,
  Grid,
  MenuItem} from '@mui/material';
  import AdapterDateFns from '@mui/lab/AdapterDateFns';
  import CustomFormLabel from '../../../../components/forms/custom-elements/CustomFormLabel';
import CustomTextField from '../../../../components/forms/custom-elements/CustomTextField';
import CustomSelect from '../../../../components/forms/custom-elements/CustomSelect';
import Globalvar from '../../../../config/globalvar.json';
import { useEffect, useState } from 'react';
import { DateRange } from '@mui/icons-material';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/lab';



const TabelKepuasanFilter = (props) => {
	
	
    return (
    	
                <Grid container spacing={2} marginBottom={3}>
                    <Grid item xs={12} sm={12} lg={6}>
                        <CustomFormLabel htmlFor="search">Pencarian</CustomFormLabel>
                        <CustomTextField
                            id="search"
                            placeholder="Cari Nama"
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={props.keywords}
                            onChange={(e) => {props.onSearch(e.target.value)}}
                        />
                    </Grid>
                    
                    <Grid item xs={12} sm={12} lg={6}>
                        <CustomFormLabel>Filter Layanan</CustomFormLabel>
                        <CustomSelect
                            fullWidth
                            size="small"
                            value={props.service}
                            onChange={(e) => {props.onChangeService(e.target.value)} }
                        >
                            <MenuItem key="0" value="0">Semua</MenuItem>
                            {props.services.map((service) => (
                            <MenuItem key={service.service_code} value={service.id}>{service.service_name}</MenuItem>
                            ))}
                        </CustomSelect>
                    </Grid>
                    {/* <Grid item xs={12} sm={12} lg={2}>
                        <CustomFormLabel>Dari Tanggal</CustomFormLabel>
                        
                        <CustomTextField
                        id="date"
                        type="date"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        size="small"
                        />
                        
                     
                    </Grid>
                    <Grid item xs={12} sm={12} lg={2}>
                        <CustomFormLabel>Sampai Tanggal</CustomFormLabel>
                        
                        <CustomTextField
                        id="date"
                        type="date"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        size="small"
                        />
                        
                     
                    </Grid> */}
                </Grid>
            
    )
}

export default TabelKepuasanFilter;