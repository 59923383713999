import React from 'react';
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Avatar,
  Button,
  Chip,
  IconButton,
  Switch} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';


import ApiConfig from "../../../../config/api";

const TabelKepuasanData = (props) => {

  // console.log(props);

    return (
        <Box
        style={{borderTop:'1px solid rgba(224,224,224,1)'}}
          sx={{
            overflow: {
              xs: 'auto',
              sm: 'auto',
              xl: 'auto',
              lg: 'auto'
            },
          }}
        >
          <Table
            aria-label="simple table"
            sx={{
              whiteSpace: 'nowrap',
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h5">Tanggal Survey</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h5">Nama</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h5">Level</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h5">Layanan</Typography>
                </TableCell>
               
              
              </TableRow>
            </TableHead>
            <TableBody>
            {!props.loading && props.data.map((row) => (
                <TableRow key={row.id}>
                  
                  <TableCell>
                    <Typography variant="h6" fontWeight="400">
                      {row.createdAt}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" fontWeight="400">
                          {(row.request.citizen) ? row.request.citizen.citizen_name : ''}
                          {(row.request.company) ? row.request.company.company_name : ''}
                          {(row.request.school) ? row.request.school.school_name : ''}
                          {(row.request.lpk) ? row.request.lpk.lpk_name : ''}
                        </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" fontWeight="400">
                          {(row.request.citizen) ? 'Masyarakat' : ''}
                          {(row.request.company) ? 'Perusahaan' : ''}
                          {(row.request.school) ? 'Sekolah' : ''}
                          {(row.request.lpk) ? 'LPK' : ''}
                        </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" fontWeight="400">
                      {row.service.service_name}
                    </Typography>
                  </TableCell>
                </TableRow>
                
              ))}
                

              {!props.loading && props.data.length === 0 && 
              <TableRow>
                <TableCell colSpan={5} style={{textAlign:'center'}}>-Tidak ada data-</TableCell>
              </TableRow>}
              {props.loading &&
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((row) => (
                  <TableRow key={row}>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
    );
}

export default TabelKepuasanData;