import React from 'react';
import {
  Card,
  CardContent,
  Box,
  Button,
  Pagination,

  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  Avatar,
  Grid,
  Fab,
  IconButton,
} from '@mui/material';


import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../../../layouts/full-layout/breadcrumb/Breadcrumb';
import { useEffect, useState } from 'react';

import Sweetalert from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


import { Survey } from '../../../../models/skm/survey.model';

import { Service } from '../../../../models/service/service.model';


import { useSelector } from 'react-redux';

import GlobalVar from '../../../../config/globalvar.json';
import PageContainer from '../../../../components/container/PageContainer';
import TabelKepuasanData from './TabelKepuasanData';
import TabelKepuasanFilter from './TabelKepuasanFilter';



const TabelSurveyKepuasan = (props) => {
    //console.log(props);
    const [type, setType] = useState('0');
    const [search, setSearch] = useState('');
    const [data, setData] = useState([]);
    const [training_type, setTrainingType] = useState([]);
    const [loading, setLoading] = useState(false);
  
    const [paginator, setPaginator] = useState({});
  
    const [perpage, setPerpage] = useState(10);
    const [page, setPage] = useState(1);

    const Swal = withReactContent(Sweetalert);

    const [id, setId] = useState('');

    const [title, setTitle] = useState('Survey');

    const [service, setService] = useState('0');
    const [service_, setService_] = useState('0');
    const [services, setServices] = useState([]);
    
    const user = useSelector((state) => state.AuthReducer.auth_data);
    




    const handlerSearch = (val) => {
        setPage(1);
        setSearch(val);
        loadData(val,1,service);
    }

    
    useEffect(() => {
        refresh();
        getService();
        // console.log(user);
    }, [props]);

    const refresh = () =>{
        loadData();
    }

    const handleService = (val) => {
        setPage(1);
        setService(val);
        loadData(search,1,val);
    }

    const getService = async () => {
        let params = {
            status : 'Active',
        }

        

        var result = await Service.getAll(params);
        setServices(result);
        //console.log(user);
    }


    const loadData = async (s=search,p=page,_service=service) => {
        if(!loading)
        {
            setLoading(true);
            var params = {
                page : p,
                per_page : perpage,
            };

            console.log(_service);

            if(_service!=0)
            {
                params.service = _service;
            }

            if(s!='')
            {
                params.search = s;
            }

            // console.log(params);
            
            var result = await Survey.get(params);
            

            if(Object.keys(result).length >0)
            {
                var paginator = result.paginator;
                setPaginator(paginator);
                setData(result.itemsList); 
            }
            setLoading(false);
        }
    }

    const handlerPageChange = (e,val) => {
        setPage(val);
        loadData(search,val);
    }

    

    return(
    <PageContainer title={title} description={title}>
    
        

        <Card>
        <Box
        sx={{
            display: {
            xs: 'block',
            sm: 'flex',
            lg: 'flex',
            },
            alignItems: 'center',
            mt: 1,
            justifyContent:'end'
        }}
        >
            {/* <Box paddingRight={2}>  
                <Button variant="contained" 
                style={{borderRadius:'20px'}} 
                color="primary" 
              
                startIcon={<FeatherIcon icon="share" />}>Export Exel</Button>
            </Box> */}
        </Box>
            <CardContent>
                <TabelKepuasanFilter
                    services={services}
                    keyword ={search}
                    service={service}
                    onSearch={handlerSearch} 
                    onChangeService = {handleService}

                    >

                </TabelKepuasanFilter>

                <TabelKepuasanData data={data} loading={loading}/>
                <Box
                display="flex"
                justifyContent="center"
                sx={{
                    mb: 2,
                    mt : 2,
                }}
                >
                {paginator.pageCount > 1 && !loading &&
                <Pagination 
                    onChange = {handlerPageChange}
                    defaultPage={1}
                    page={page}
                    count={paginator.pageCount} 
                    variant="outlined" 
                    color="primary" />
                }
                </Box>
                
            </CardContent>
        </Card>

    </PageContainer>
)};

export default TabelSurveyKepuasan;
