import ApiConfig from "../../config/api";
import { WebServices } from "../WebServices";

const get = async (params=null) => {
    let url = ApiConfig.SKM_KUISIONER;
    if(params)
    {
        url += '?' + new URLSearchParams(params);
        //console.log(url);
    }
    return WebServices.get(url);
}


const getDetail = async (id) => {
    let url = ApiConfig.SKM_KUISIONER + '/' + id; 
    return WebServices.get(url);
}

const add = async (data) => {
    let url = ApiConfig.SKM_KUISIONER_ADD;
    return WebServices.post(url,data);
}

const update = async (data, id) => {
    let url = ApiConfig.SKM_KUISIONER_EDIT + '/' + id;
    return WebServices.put(url,data);
}

const _delete = async (id) => {
    let url = ApiConfig.SKM_KUISIONER_DELETE + '/' + id;
    return WebServices.delete(url);
}


export const Kuesioner = {
    get,
    getDetail,
    add,
    update,
    delete : _delete,
};
