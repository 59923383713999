import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Box, Grid } from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import Chart from 'react-apexcharts';
import { useTheme } from '@mui/material/styles';

const StatusLayanan = (props) => {
	
  
  const [label, setLabel] = useState([]);
  const [value, setValue] = useState([]);
  
  useEffect(() => {
    let labels = [];
    let values = [];
    if(props.responden)
    {
      let responden = props.responden;
      let i=0;
      for(i=0;i<responden.length;i++)
      {
        labels.push(responden[i].rating_desc);
        values.push(responden[i].jumlah);
      }
    }
  
    setLabel(labels);
    setValue(values);
  }, [props])
  
  const theme = useTheme();
  const primary = theme.palette.primary.main;
  // chart
  const optionResponded = {
    labels: label,
    
    chart: {
      height: 145,
      type: 'donut',
      foreColor: '#adb0bb',
      fontFamily: 'DM sans',
    },
    colors: ['#fec90f', '#00fffb', '#6a00ff', '#ff0d00'],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    stroke: {
      colors: ['transparent'],
    },
    plotOptions: {
      pie: {
        donut: {
          size: '65%',
          background: 'transparent',
          labels: {
            show: false,
            name: {
              show: true,
              fontSize: '18px',
              color: undefined,
              offsetY: -10,
            },
            value: {
              show: false,
              color: '#98aab4',
            },
            total: {
              show: false,
              label: 'Our Visitors',
              color: '#98aab4',
            },
          },
        },
      },
    },
    tooltip: {
      theme: 'dark',
      fillSeriesColor: false,
    },
  };
  const seriesResponded = value;
  return (
      <CardContent  alignItems="center">
        <Grid display="flex" alignItems="center" justifyContent="flex-end">
          <Grid item xs={6} xl={7} display="flex" alignItems="center" justifyContent="flex-start">
            <Grid>
                <Typography
                  fontWeight="500"
                  variant="h1"
                  sx={{
                    lineHeight: '35px',
                  }}
                >
                {(props.data) ? props.data.nama_kategori : ''} {(props.data && props.data.kategori!="") ? '('+props.data.kategori+')' : ''}
                </Typography>
                <Typography color="textSecondary" variant="h6">
                   SKM : {(props.data) ? props.data.ikm : '0'}
                </Typography>
            </Grid>
        
          </Grid>
          
          <Grid item xs={6} xl={5} display="flex" alignItems="center" justifyContent="flex-end">
            <Box
              sx={{
                position: 'relative',
                mt: 1,
              }}
            >
              <Chart
                options={optionResponded}
                series={seriesResponded}
                type="donut"
                height="145"
              />
              <Typography
                color="textSecondary"
                sx={{
                  position: 'absolute',
                  left: '46%',
                  top: '38%',
                }}
              >
            
              <FeatherIcon icon="users" height="24" width="24" />
         
              </Typography>
            </Box>
          </Grid>
          
        </Grid>
      </CardContent>
  );
};

export default StatusLayanan;
