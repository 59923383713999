import React from 'react';
import {
  Card,
  CardContent,
  Grid,
  MenuItem} from '@mui/material';
  
  import CustomFormLabel from '../../../components/forms/custom-elements/CustomFormLabel';
import CustomTextField from '../../../components/forms/custom-elements/CustomTextField';
import CustomSelect from '../../../components/forms/custom-elements/CustomSelect';
import Globalvar from '../../../config/globalvar.json';
import { useEffect, useState } from 'react';



const QuisionerFilter = (props) => {
	
	
    return (
    	
                <Grid container spacing={2} marginBottom={3}>
                    <Grid item xs={12} sm={12} lg={9}>
                        <CustomFormLabel htmlFor="search">Pencarian</CustomFormLabel>
                        <CustomTextField
                            id="search"
                            placeholder="Cari Pertanyaan"
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={props.keywords}
                            onChange={(e) => {props.onSearch(e.target.value)}}
                        />
                    </Grid>
                    
                    <Grid item xs={12} sm={12} lg={3}>
                        <CustomFormLabel>Status</CustomFormLabel>
                        <CustomSelect
                            fullWidth
                            size="small"
                            value={props.type}
                            onChange={(e) => {props.onChangetype(e.target.value)} }
                        >
                            <MenuItem key="type_0" value="0">Semua</MenuItem>
                            <MenuItem key="Y" value="Y">Aktif</MenuItem>
                            <MenuItem key="N" value="N">Non-aktif</MenuItem>
                        </CustomSelect>
                    </Grid>
                </Grid>
            
    )
}

export default QuisionerFilter;