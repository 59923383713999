import React from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Avatar,
  Button,
  Chip,
  IconButton,
  Switch,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";

import ApiConfig from "../../../config/api";
import { data } from "jquery";

const QuisionerData = (props) => {
  return (
    <Box
      style={{ borderTop: "1px solid rgba(224,224,224,1)" }}
      sx={{
        overflow: {
          xs: "auto",
          sm: "auto",
          xl: "auto",
          lg: "auto",
        },
      }}
    >
      <Table
        aria-label="simple table"
        sx={{
          whiteSpace: "nowrap",
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="h5">Kode</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h5">Pertanyaan</Typography>
            </TableCell>
            
            <TableCell width={50} align="center">
              <Typography variant="h5">Action</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!props.loading && props.data.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                <Typography variant="h6" fontWeight="400">
                  {row.kode}
                </Typography>
              </TableCell>
              
              <TableCell>
                <Typography variant="h6" fontWeight="400">
                  {row.nama_kuesioner}
                </Typography>
              </TableCell>
              
              <TableCell>
                {/* <Switch defaultChecked  /> */}
                
                <IconButton
                  title="Edit"
                  component={Link}
                  to={`/quisioner/edit/${row.id}`}
                >
                  <FeatherIcon
                    color="#05b2bd"
                    icon="edit"
                    width="18"
                    height="18"
                  />
                </IconButton>
                <IconButton
                  title="Hapus"
                  onClick={(e) => props.onDelete(row.id)}
                >
                  <FeatherIcon
                    color="#e67e5f"
                    icon="x"
                    width="18"
                    height="18"
                  />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          {!props.loading && props.data.length === 0 && (
            <TableRow>
              <TableCell colSpan={5} style={{ textAlign: "center" }}>
                -Tidak ada data-
              </TableCell>
            </TableRow>
          )}

          {props.loading && (
            <TableRow>
              <TableCell colSpan={5}>
                <Skeleton />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Box>
  );
};

export default QuisionerData;
